import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import arrowLeft from '../../images/arrow-left.png';
import loader from "../../images/enquiry-loader.gif";
import Popup from "../cwu-popup"
const isSSR = typeof window === "undefined";

class Banner extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        isPopUpOpen: false,
        isFixedHeader: false
    };

    closePopup() {
        this.setState({
            isPopUpOpen: false
        }); 
    }

    openPopup = (e) => {
        e.preventDefault();
        this.setState({
            isPopUpOpen: true
        }); 
    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const target = document.getElementById('home-menu').offsetTop;

        if(window.pageYOffset >  target ) {
          this.setState({
            isFixedHeader: true
          });
        } else {
          this.setState({
            isFixedHeader: false
          });
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {

        about:wordpressPage(slug: { eq: "about" }){
            acf {
                about_banner_image {
                    source_url
                }
                about_banner_heading
                about_banner_content
                about_wwd_heading
                about_wwd_content
                about_wwd_button
                about_wwd_button_link
            }
        }

        home:wordpressPage(slug: { eq: "home" }){
            acf {
                cwup_heading
                cwup_subheading
                cwup_first_name
                cwup_last_name
                cwup_email
                cwup_phone
                cwup_submit_button
                cwup_image {
                    source_url
                }
                cwup_success_1
                cwup_success_2
                cwup_error_1
                cwup_error_2
            }
        }

        allWordpressWpApiMenusMenusItems(filter: {
            slug:{
              eq:"main-menu"
            }
          }){
            edges{
              node{
                name
                items{
                  title
                  object_slug
                  url
                }
              }
            }
        }

    } 
    `} render={props => (
        <div>
        {!isSSR && (
            <div>
            <div className="about-banner">
                <div className="about-banner-left" data-aos="fade-up" data-aos-duration="1200" style={{backgroundImage: 'url(' + props.about.acf.about_banner_image.source_url + ')'}}>
                    <Link to="/"><img className="arrow" src={ arrowLeft } alt="" /></Link>
                    {/* <img className="banner-image" src={props.about.acf.about_banner_image.source_url} alt="" /> */}
                </div>
                <div className="about-banner-right" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500">
                    <div className="title">{props.about.acf.about_banner_heading}</div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: props.about.acf.about_banner_content }}></div>
                </div>
            </div>
            <div id="home-menu" className="home-menu" >
                <div className="frame">
                    {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                        <div key={item.title} className="menu-item ">
                            { item.url === '#connectwithus' ? (
                                <a onClick = {(event) => this.openPopup(event)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                            ) : (
                                <Link className={( item.object_slug === 'home' ? window.location.pathname === '/' ? 'active' : '' : window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ item.object_slug === 'home' ? "/" : `/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className={ "home-menu headermenu-f " + (this.state.isFixedHeader ? "headermenu-v" : "")  } >
                <div className="frame">
                    {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                        <div key={item.title} className="menu-item ">
                            { item.url === '#connectwithus' ? (
                                <a onClick = {(event) => this.openPopup(event)} dangerouslySetInnerHTML={{ __html: item.title }}></a>
                            ) : (
                                <Link className={( item.object_slug === 'home' ? window.location.pathname === '/' ? 'active' : '' : window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ item.object_slug === 'home' ? "/" : `/${item.object_slug}`} key={item.title} dangerouslySetInnerHTML={{ __html: item.title }}></Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="about-banner-bottom">
                <div className="frame">
                    <div className="title" data-aos="fade-up">{ props.about.acf.about_wwd_heading }</div>
                    <div className="content" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: props.about.acf.about_wwd_content }}></div>
                    <div className="button" data-aos="fade-up"><a href="https://app.yc360.com.au/">{ props.about.acf.about_wwd_button }</a></div> 
                </div>
            </div>

            <Popup isPopUpOpen = { this.state.isPopUpOpen } closePopup={() => this.closePopup()} />
            
            </div>
            )}
        </div>
    ) } />
    }
};

export default Banner;