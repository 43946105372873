import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

class Process extends Component {

    render() {
    return <StaticQuery query={graphql`
    {
        wordpressPage(slug: { eq: "about" }){
            acf {
                aup_title
                aup_process_1_title
                aup_process_1_content
                aup_process_2_title
                aup_process_2_content
                aup_process_3_title
                aup_process_3_content
                aup_process_4_title
                aup_process_4_content
                aup_process_5_title
                aup_process_5_content
                aup_content
                aup_heading_1
                aup_content_1
                aup_heading_2
                aup_content_2
                aup_heading_3
                aup_content_3
            }
        }
    }
    `} render={props => (
        <div>
        <div className="about-process">
            <div className="frame">
                <div className="title">{props.wordpressPage.acf.aup_title}</div>
                <div className="process-list-wrapper">
                    <div className="process-list" data-aos="fade-up">
                        <div className="number">1</div>
                        <div className="process-list-right">
                            <div className="pl-title">{props.wordpressPage.acf.aup_process_1_title}</div>
                            <div className="pl-content">{props.wordpressPage.acf.aup_process_1_content}</div>
                        </div>
                    </div>
                    <div className="process-list" data-aos="fade-up">
                        <div className="number">2</div>
                        <div className="process-list-right">
                            <div className="pl-title">{props.wordpressPage.acf.aup_process_2_title}</div>
                            <div className="pl-content">{props.wordpressPage.acf.aup_process_2_content}</div>
                        </div>
                    </div>
                    <div className="process-list" data-aos="fade-up">
                        <div className="number">3</div>
                        <div className="process-list-right">
                            <div className="pl-title">{props.wordpressPage.acf.aup_process_3_title}</div>
                            <div className="pl-content">{props.wordpressPage.acf.aup_process_3_content}</div>
                        </div>
                    </div>
                    <div className="process-list" data-aos="fade-up">
                        <div className="number">4</div>
                        <div className="process-list-right">
                            <div className="pl-title">{props.wordpressPage.acf.aup_process_4_title}</div>
                            <div className="pl-content">{props.wordpressPage.acf.aup_process_4_content}</div>
                        </div>
                    </div>
                    <div className="process-list" data-aos="fade-up">
                        <div className="number">5</div>
                        <div className="process-list-right">
                            <div className="pl-title">{props.wordpressPage.acf.aup_process_5_title}</div>
                            <div className="pl-content">{props.wordpressPage.acf.aup_process_5_content}</div>
                        </div>
                    </div>
                </div>
                <div className="triangle" data-aos="fade-up"></div>
                <div className="process-content" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aup_content }}></div>
            </div>
        </div>
        <div className="about-process-content-wrapper">
            <div className="frame">
                <div className="about-process-content-block" data-aos="fade-up" data-aos-duration="1200">
                    <div className="about-process-heading">{props.wordpressPage.acf.aup_heading_1}</div>
                    <div className="about-process-content" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aup_content_1 }}></div>
                </div>
                <div className="about-process-content-block" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500">
                    <div className="about-process-heading">{props.wordpressPage.acf.aup_heading_2}</div>
                    <div className="about-process-content" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aup_content_2 }}></div>
                </div>
                <div className="about-process-content-block" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="1000">
                    <div className="about-process-heading">{props.wordpressPage.acf.aup_heading_3}</div>
                    <div className="about-process-content" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aup_content_3 }}></div>
                </div>
            </div>
        </div>
        </div>
    ) } />
    }
};

export default Process;