import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import React, { Component } from 'react'
import DefaultTemplate from "../templates/default"
import Banner from "../components/about-us/banner"
import Process from "../components/about-us/process"
import About from "../components/about-us/about"
import AOS from 'aos';
import 'aos/dist/aos.css';

class AboutUs extends Component {

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render(){
        return  <DefaultTemplate> 
                    <Banner />  
                    <Process />
                    <About />
                </DefaultTemplate>
    }

}
   
export default AboutUs
   