import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
const isSSR = typeof window === "undefined";
const OwlCarousel = React.lazy(() =>
    import('react-owl-carousel')
)

class Process extends Component {

    state= {
        responsive:{
            0: {
                items: 1
            },
            481: {
                items: 2
            },
            601: {
                items: 3
            },
            768: {
                items: 4
            },
            992: {
                items: 5
            }
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {
        wordpressPage(slug: { eq: "about" }){
            acf {
                aua_title
                aua_content
                aua_bottom_content
                aua_image {
                    source_url
                }
            }
        }
        allWordpressWpAboutImages {
            edges {
              node {
                id
                featured_media {
                    source_url
                }
              }
            }
        }
    }
    `} render={props => (
        <div>
        <div className="about">
            <div className="frame">
                <div className="title" data-aos="fade-up">{ props.wordpressPage.acf.aua_title }</div>
                <div className="content" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aua_content }}></div>
           </div>
        </div>
        <div className="about-slider">
        {!isSSR && (
            <React.Suspense fallback={<div></div>}>
                <OwlCarousel
                    className="owl-theme"
                    loop = { true }
                    dots = { false }
                    autoplay 
                    autoplayTimeout = { 6000 }
                    items = {5}
                    animateOut = { 'fadeOut' }
                    animateIn = { 'fadeIn' }
                    data-aos="fade-up"
                    responsive={this.state.responsive} 
                    margin = { 40 }
                    stagePadding = { 50 }
                >
                    {
                        props.allWordpressWpAboutImages.edges.map(item => (
                            <div className="item" key={item.node.id} >
                                <img src={item.node.featured_media.source_url.replace(".jpg", "-436x290.jpg") } />
                            </div> 
                        ))
                    }

                </OwlCarousel>
            </React.Suspense>
        )}
        </div>
        <div className="about-bottom">
            <div className="about-banner-left" data-aos="fade-right">
                <div className="content" dangerouslySetInnerHTML={{ __html: props.wordpressPage.acf.aua_bottom_content }}></div>
            </div>
            <div className="about-banner-right" data-aos="fade-left">
                <img src={props.wordpressPage.acf.aua_image.source_url} alt="" />
            </div>
        </div>
        </div>
    ) } />
    }
};

export default Process;